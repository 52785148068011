import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppCommonModule } from '@app/components/common/appCommonModule';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';

@Component({
    selector: 'uni-altinn-salary-settings',
    imports: [CommonModule, AppCommonModule, DesignSystemModule],
    templateUrl: './altinn-salary-settings.component.html',
    styleUrls: ['./altinn-salary-settings.component.sass'],
})
export class AltinnSalarySettingsComponent {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();
}
