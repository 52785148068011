import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { UniFormModule } from '@uni-framework/ui/uniform';
import { FormsModule } from '@angular/forms';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { CompanySalary } from '@uni-entities';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'uni-tax-free-organization-settings',
    imports: [CommonModule, UniFormModule, DesignSystemModule, FormsModule],
    templateUrl: './tax-free-organization-settings.component.html',
    styleUrls: ['./tax-free-organization-settings.component.sass'],
})
export class TaxFreeOrganizationSettingsComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();

    hasChanges: boolean;
    busy: boolean;
    companySalary: CompanySalary;

    constructor(
        private companySalaryService: CompanySalaryService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.companySalaryService
            .getCompanySalary()
            .subscribe({
                next: (settings: CompanySalary) => {
                    this.companySalary = settings;
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    navigate(step: number): void {
        this.step.emit(step);
    }

    save(): void {
        this.busy = true;
        this.companySalaryService
            .Put(this.companySalary.ID, this.companySalary)
            .subscribe({
                next: (settings: CompanySalary) => {
                    this.companySalary = settings;
                    this.hasChanges = false;
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }
}
