import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { FieldType, UniFieldLayout, UniFormModule } from '@uni-framework/ui/uniform';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { BankAccount, CompanySettings, StatusCode } from '@uni-entities';
import { ErrorService } from '@app/services/common/errorService';
import { ConfirmActions, UniBankAccountModal, UniModalService } from '@uni-framework/uni-modal';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, forkJoin, of } from 'rxjs';
import { BankAccountService } from '@app/services/accounting/bankAccountService';

@Component({
    selector: 'uni-salary-bank-settings',
    imports: [CommonModule, DesignSystemModule, UniFormModule, UniFrameworkModule],
    templateUrl: './salary-bank-settings.component.html',
    styleUrls: ['./salary-bank-settings.component.sass'],
})
export class SalaryBankSettingsComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();

    busy: boolean;
    settings: CompanySettings;
    isDirty: boolean;
    fields: UniFieldLayout[];

    constructor(
        private errorService: ErrorService,
        private companySettingsService: CompanySettingsService,
        private modalService: UniModalService,
        private bankAccountService: BankAccountService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.companySettingsService
            .getCompanySettings()
            .subscribe({
                next: (settings: CompanySettings) => {
                    settings = this.setAccontDisplayFields(settings);
                    this.settings = settings;
                    this.setFields();
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    saveSettings(): void {
        this.busy = true;
        if (this.settings.CompanyBankAccount['_isDirty'] && this.settings.CompanyBankAccountID) {
            this.settings.SalaryBankAccount = this.settings.CompanyBankAccount;
            this.settings.SalaryBankAccountID = this.settings.CompanyBankAccount.ID;
            this.settings.CompanyBankAccount = null;
        }

        let accounts = [
            ...(this.settings.CompanyBankAccount && !this.settings.CompanyBankAccount.ID
                ? [this.settings.CompanyBankAccount]
                : []),
            ...(this.settings.TaxBankAccount && !this.settings.TaxBankAccount.ID ? [this.settings.TaxBankAccount] : []),
            ...(this.settings.SalaryBankAccount && !this.settings.SalaryBankAccount.ID
                ? [this.settings.SalaryBankAccount]
                : []),
        ];

        (accounts.length ? this.bankAccountService.Post(accounts) : of(null))
            .pipe(
                switchMap((savedAccounts: any[]) => {
                    return this.companySettingsService.Put(this.settings.ID, {
                        ID: this.settings.ID,
                        ...(this.settings.CompanyBankAccount
                            ? [
                                  {
                                      CompanyBankAccountID:
                                          savedAccounts?.find(
                                              (x) =>
                                                  x.AccountNumber === this.settings.CompanyBankAccount?.AccountNumber,
                                          )?.ID ??
                                          this.settings.CompanyBankAccount?.ID ??
                                          null,
                                  },
                              ]
                            : []),
                        TaxBankAccountID:
                            savedAccounts?.find((x) => x.AccountNumber === this.settings.TaxBankAccount?.AccountNumber)
                                ?.ID ??
                            this.settings.TaxBankAccount?.ID ??
                            null,
                        SalaryBankAccountID:
                            savedAccounts?.find(
                                (x) => x.AccountNumber === this.settings.SalaryBankAccount?.AccountNumber,
                            )?.ID ??
                            this.settings.SalaryBankAccount?.ID ??
                            null,
                    });
                }),
                switchMap(() => this.companySettingsService.getCompanySettings()),
            )
            .subscribe({
                next: (settings: CompanySettings) => {
                    settings = this.setAccontDisplayFields(settings);
                    this.settings = settings;
                    this.setFields();
                    this.isDirty = false;
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    navigate(step: number): void {
        if (this.isDirty) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.step.emit(step);
                    }
                },
            });
        } else {
            this.step.emit(step);
        }
    }

    close(): void {
        if (this.isDirty) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.cancel.emit(true);
                    }
                },
            });
        } else {
            this.cancel.emit(true);
        }
    }

    change(event: any): void {
        this.isDirty = true;
        if (event['SalaryBankAccount'] && this.settings.SalaryBankAccount) {
            this.settings.SalaryBankAccount['_isDirty'] = true;
        }

        if (event['TaxBankAccount'] && this.settings.TaxBankAccount) {
            this.settings.TaxBankAccount['_isDirty'] = true;
        }

        if (event['CompanyBankAccount'] && this.settings.CompanyBankAccount) {
            this.settings.CompanyBankAccount['_isDirty'] = true;
        }
    }

    private setAccontDisplayFields(settings: CompanySettings) {
        settings.BankAccounts = settings.BankAccounts.map((account) => {
            account['_displayValue'] = account.AccountNumber;

            if (settings.CompanyBankAccountID === account.ID) {
                account['_displayValue'] = (account['_displayValue'] ?? account.AccountNumber) + ` (Driftskonto)`;
            }

            if (settings.SalaryBankAccountID === account.ID) {
                account['_displayValue'] = (account['_displayValue'] ?? account.AccountNumber) + ` (Lønnskonto)`;
            }

            if (settings.TaxBankAccountID === account.ID) {
                account['_displayValue'] = (account['_displayValue'] ?? account.AccountNumber) + ` (Skattetrekkskonto)`;
            }
            return account;
        });
        return settings;
    }

    private openUnsavedChangesModal(): Observable<ConfirmActions> {
        return this.modalService.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har endringer som ikke er lagret, disse vil bli forkastet.',
            buttonLabels: {
                accept: 'Forkast endringer og fortsett',
                cancel: 'Avbryt',
            },
        }).onClose;
    }

    private setFields(): void {
        this.fields = [
            <UniFieldLayout>{
                Property: `${this.settings?.SalaryBankAccount ? 'SalaryBankAccount' : 'CompanyBankAccount'}`,
                Label: 'Lønnskonto',
                FieldType: FieldType.MULTIVALUE,
                Placeholder: 'Legg til kontonummer',
                Tooltip: {
                    Text: 'En lønnskonto er en bankkonto som brukes til å motta og betale ut lønn til ansatte.',
                },
                Options: {
                    hideEditAndDelete: true,
                    listProperty: 'BankAccounts',
                    displayValue: `_displayValue`,
                    linkProperty: `ID`,
                    storeResultInProperty: `${this.settings?.SalaryBankAccount ? 'SalaryBankAccount' : 'CompanyBankAccount'}`,
                    editor: (bankaccount: BankAccount) => {
                        if (!bankaccount) {
                            bankaccount = new BankAccount();
                            bankaccount.ID = 0;
                        }

                        const modal = this.modalService.open(UniBankAccountModal, {
                            data: bankaccount,
                            closeOnClickOutside: false,
                        });
                        return modal.onClose
                            .pipe(
                                tap((account) => {
                                    if (account) {
                                        account.Label = 'Driftskonto';
                                        account.BankAccountType = 'company';
                                        account.CompanySettingsID = this.settings.ID;
                                        account['_displayValue'] = `${account.AccountNumber}`;
                                        this.isDirty = true;
                                    }
                                }),
                            )
                            .toPromise();
                    },
                },
            },
            <UniFieldLayout>{
                Property: `TaxBankAccount`,
                Label: 'Skattetrekkskonto',
                FieldType: FieldType.MULTIVALUE,
                Placeholder: 'Legg til kontonummer',
                Tooltip: {
                    Text: 'En skattetrekkskonto er en bankkonto der arbeidsgivere må sette av skatt og avgifter fra lønnen til de ansatte før de betaler den ut.',
                },
                Options: {
                    hideEditAndDelete: true,
                    listProperty: 'BankAccounts',
                    displayValue: `_displayValue`,
                    linkProperty: `ID`,
                    storeResultInProperty: `TaxBankAccount`,
                    editor: (bankaccount: BankAccount) => {
                        if (!bankaccount) {
                            bankaccount = new BankAccount();
                            bankaccount.ID = 0;
                        }

                        const modal = this.modalService.open(UniBankAccountModal, {
                            data: bankaccount,
                            closeOnClickOutside: false,
                        });

                        return modal.onClose
                            .pipe(
                                tap((account) => {
                                    if (account) {
                                        account.Label = 'Skattetrekkskonto';
                                        account.BankAccountType = 'tax';
                                        account.CompanySettingsID = this.settings.ID;
                                        account['_displayValue'] = `${account.AccountNumber}`;
                                        this.isDirty = true;
                                    }
                                }),
                            )
                            .toPromise();
                    },
                },
            },
        ];
    }
}
