import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { FieldType, UniFieldLayout, UniFormModule } from '@uni-framework/ui/uniform';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { Account, CompanySalary } from '@uni-entities';
import { ErrorService } from '@app/services/common/errorService';
import { Observable, of } from 'rxjs';
import { AccountService } from '@app/services/accounting/accountService';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';

@Component({
    selector: 'uni-salary-accounts-settings',
    imports: [CommonModule, DesignSystemModule, UniFormModule, UniFrameworkModule],
    templateUrl: './salary-accounts-settings.component.html',
    styleUrls: ['./salary-accounts-settings.component.sass'],
})
export class SalaryAccountsSettingsComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();

    fields: UniFieldLayout[] = [];
    settings: CompanySalary;
    busy: boolean;
    hasChanges: boolean;

    constructor(
        private companySalaryService: CompanySalaryService,
        private errorService: ErrorService,
        private accountService: AccountService,
        private modalService: UniModalService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.companySalaryService
            .getCompanySalary()
            .subscribe({
                next: (settings: CompanySalary) => {
                    this.settings = settings;
                    this.setFields();
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    save(): void {
        this.busy = true;
        this.companySalaryService
            .Put(this.settings.ID, this.settings)
            .subscribe({
                next: (settings: CompanySalary) => {
                    this.settings = settings;
                    this.hasChanges = false;
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    navigate(step: number): void {
        if (this.hasChanges) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.step.emit(step);
                    }
                },
            });
        } else {
            this.step.emit(step);
        }
    }

    close(): void {
        if (this.hasChanges) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.cancel.emit(true);
                    }
                },
            });
        } else {
            this.cancel.emit(true);
        }
    }

    private openUnsavedChangesModal(): Observable<ConfirmActions> {
        return this.modalService.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har endringer som ikke er lagret, disse vil bli forkastet.',
            buttonLabels: {
                accept: 'Forkast endringer og fortsett',
                cancel: 'Avbryt',
            },
        }).onClose;
    }

    private setFields(): void {
        this.fields = [
            <UniFieldLayout>{
                Label: 'Kostnad feriepenger',
                Property: 'MainAccountCostVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
            <UniFieldLayout>{
                Label: 'Avsatt feriepenger',
                Property: 'MainAccountAllocatedVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
            <UniFieldLayout>{
                Label: 'Konto for avsatt arbeidsgiveravgift',
                Property: 'MainAccountAllocatedAGA',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
            <UniFieldLayout>{
                Label: 'Konto for kostnad til arbeidsgiveravgift',
                Property: 'MainAccountCostAGA',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
            <UniFieldLayout>{
                Label: 'Konto for avsatt arbeidsgiveravgift av feriepenger',
                Property: 'MainAccountAllocatedAGAVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
            <UniFieldLayout>{
                Label: 'Konto for kostnad arbeidsgiveravgift av feriepenger',
                Property: 'MainAccountCostAGAVacation',
                FieldType: FieldType.AUTOCOMPLETE,
                Options: this.accountService.getSearchConfigUniForm('AccountNumber'),
            },
        ];
    }
}
