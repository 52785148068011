import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { StatisticsService } from '@app/services/common/statisticsService';
import { switchMap } from 'rxjs/operators';
import { WageTypeService } from '@app/components/salary/shared/services/wageType/wageTypeService';
import { of } from 'rxjs';
import { ErrorService } from '@app/services/common/errorService';

@Component({
    selector: 'uni-salary-settings-summary',
    imports: [CommonModule, DesignSystemModule, UniFrameworkModule],
    templateUrl: './salary-settings-summary.component.html',
    styleUrls: ['./salary-settings-summary.component.sass'],
})
export class SalarySettingsSummaryComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();
    @Output() done: EventEmitter<boolean> = new EventEmitter();

    busy: boolean;

    constructor(
        private statisticsService: StatisticsService,
        private wageTypeService: WageTypeService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.statisticsService
            .GetAllUnwrapped('model=wagetype&select=count(ID) as count')
            .pipe(switchMap((data) => (!data[0].count ? this.wageTypeService.syncWagetypes() : of())))
            .subscribe({
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    navigateToEmployee(): void {
        this.done.emit(true);
    }
}
